<template>
  <div id="centerLeft1">
    <div class="title">
      <span class="text">全乡产业种植面积/分类占比</span>
    </div>
    <div class="top">
      <div class="top-item" v-for="(item, index) in topdata" :key="index">
        <img src="../../assets/img/triangle.png" alt="" class="top-icon">
        <div class="top-text-content">
          <div class="top-text">{{ item.text }}</div>
          <div class="num-content">
            <span class="top-num" >{{ item.num }}</span>
            <span class="top-unit">{{ item.unit }}</span>
          </div>
        </div>
        <img :src="item.url" alt="" class="top-img">
      </div>
    </div>
    <!-- <div class="content">
      <div class="item" v-for="(item, index) in data" :key="index">
      <div class="img-content">
        <div class="center">
          <img :src="item.url" alt="" class="item-img">
          <div class="img-number-content">
            <span class="img-number" v-change="{ eUnit: '' }">{{ item.number }}</span>
            <span class="img-text">{{ item.text }}</span>
          </div>
        </div>
        <div class="item-title">{{ item.title }}</div>
      </div> -->
      <!-- <div class="item-text-box">
        <div class="item-text" v-for="(textItem, j) in item.itemText" :key="j">
          <div class="item-color" :style="{ 'background-color': textItem.color }"></div>
          <div class="text-content">{{ textItem.text }}</div>
          <div class="item-line"></div>
          <div class="item-num">{{ textItem.num }}</div>
        </div>
      </div> -->
    <!-- </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      topdata: [
        {
          text: '果园数量',
          num: 2083,
          unit: '户',
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page1-centerLeft-icon1.png'
        },
        {
          text: '种植面积',
          num: 35000,
          unit: '亩',
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page1-centerLeft-icon2.png'
        },
        {
          text: '挂果面积',
          num: 32000,
          unit: '亩',
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page1-centerLeft-icon2.png'
        },
        {
          text: '总产量',
          num: 64000,
          unit: '吨',
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page1-centerLeft-icon3.png'
        },
        {
          text: '平均亩产量',
          num: 4000,
          unit: '斤',
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page1-centerLeft-icon1.png'
        },
        {
          text: '平均产地价格',
          num: '3.8-4.2',
          unit: '元',
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page1-centerLeft-icon4.png'
        },
        // {
        //   text: '产业集中度',
        //   num: 75,
        //   unit: '%',
        //   url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page1-centerLeft-icon5.png'
        // },
        // {
        //   text: '成本收益',
        //   num: 41,
        //   unit: '%',
        //   url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page1-centerLeft-icon6.png'
        // }
      ],
      data: [
        {
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/fruit.png',
          title: '东村智慧果园',
          number: 4,
          text: '亩'
        },
        {
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/fruit.png',
          title: '东村智慧果园',
          number: 4,
          text: '亩'
        },
        {
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/fruit.png',
          title: '姜家台智慧果园',
          number: 4,
          text: '亩'
        },
        {
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/fruit.png',
          title: '照八寺智慧果园',
          number: 25,
          text: '亩'
        },
        {
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/fruit.png',
          title: '湫塬村智慧果园',
          number: 4,
          text: '亩'
        }
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
#centerLeft1 {
  width: 400px;
  margin-bottom: 24px;

  .title {
    width: 100%;
    height: 32px;
    background-image: url('../../assets/img/title.png');
    background-size: cover;
    display: flex;
    align-items: center;

    .text {
      margin-left: 38px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1.78px;
    }
  }

  .top {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    margin-top: 14px;
    flex-wrap: wrap;

    .top-item {
      width: 182px;
      height: 70px;

      position: relative;
      display: flex;
      align-items: center;
      margin-right: 16px;
      margin-bottom: 13px;

      .top-icon {
        position: absolute;
        top: 5px;
        left: 5px;
      }

      .top-img {
        position: absolute;
        right: 12px;
        top: 15px;
      }

      .top-text-content {
        margin-left: 26px;

        .top-text {
          font-size: 14px;
          font-weight: 400;
          color: rgba(217, 231, 255, 1);
          margin-bottom: 6px;
        }

        .top-num {
          font-size: 20px;
          font-weight: 700;
          letter-spacing: 2px;
          color: rgba(79, 240, 255, 1);
          margin-right: 4px;
        }

        .top-unit {
          font-size: 12px;
          font-weight: 400;
          color: rgba(217, 231, 255, 1);
        }

        z-index: 99;
      }

      .top-img {
        z-index: 99;
      }
    }

    .top-item::after {
      content: "";
      background-image: url('https://nonglian.oss-cn-shanghai.aliyuncs.com/page1-centerLeft1-bg.png'); // 背景图片的路径
      opacity: 0.3;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: -99;
    }
  }
.content{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
  .item {
    width: 151px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    margin-right: 41px;
    margin-left: 20px;
    &:nth-child(2n){
      margin-right: 0;
    }

    .img-content {
      width: 151px;
      height: 143px;
      margin-right: 20px;

      // text-align: center;
      .center {
        width: 100%;
        height: 113px;
        background: url('https://nonglian.oss-cn-shanghai.aliyuncs.com/page1-centerLeft2-bg.png') 0 0 no-repeat;
        background-size: cover;
        margin: 22px 0 9px 0;
        position: relative;

        .item-img {
          width: 40px;
          height: 36px;
          position: absolute;
          left: 50%;
          top: -13px;
          transform: translate(-30%, 0);
        }

        .img-number-content {
          width: 90px;
          position: absolute;
          left: 50%;
          top: 30px;
          transform: translate(-43%, 0);
          text-align: center;

          .img-number {
            font-size: 20px;
            font-weight: 700;
            letter-spacing: 2px;
            color: rgba(255, 255, 255, 1);
            margin-left: 4px;
          }

          .img-text {
            font-size: 12px;
            font-weight: 400;
            color: rgba(217, 231, 255, 1);
          }
        }
      }

      .item-title {
        text-align: center;
        padding-left: 17px;
        margin-top: -15px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(79, 240, 255, 1);
      }
    }



    .item-text {
      width: 245px;
      height: 24px;
      background: rgba(108, 128, 151, 0.1);
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 11px;
      // margin-left: -10px;

      .item-color {
        width: 7px;
        height: 7px;
      }

      .item-line {
        width: 94px;
        height: 1px;
        border: 1px solid rgba(102, 225, 223, 1);
      }

      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
  }

}
</style>