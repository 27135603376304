<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <!-- 标题行 -->
        <div class="title">
          <img src="../assets/img/logo.png" alt="" class="big-title-img">
          <div class="time">
            <span class="year">{{ dateYear }}</span>
            <span class="week">{{ dateWeek }}</span>
            <span class="day">{{ dateDay }}</span>
          </div>
        </div>
        <!-- 内容 -->
        <div class="content">
          <!-- 左边 -->
          <div class="left">
            <centerLeft2 />
            <centerLeft3 />
            <centerLeft1 :num="totalData[3].num" />
            <!-- <centerLeft3 /> -->
          </div>
          <!-- 中间 -->
          <div class="center">
            <!-- <div class="dataAnalysis-box">
              <dv-border-box-6 class="dataAnalysis">农业机械智能化</dv-border-box-6>
              <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/center-icon1.png" alt="" class="dataAnalysis-img">
            </div>
            <div class="dataAnalysis-box2">
              <dv-border-box-6 class="dataAnalysis">农产品质量追溯</dv-border-box-6>
              <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/center-icon1.png" alt="" class="dataAnalysis-img">
            </div>
            <div class="dataAnalysis-box3">
              <dv-border-box-6 class="dataAnalysis">农业大数据分析</dv-border-box-6>
              <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/center-icon1.png" alt="" class="dataAnalysis-img">
            </div>
            <div class="dataAnalysis-box4">
              <dv-border-box-6 class="dataAnalysis">农产品营销</dv-border-box-6>
              <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/center-icon1.png" alt="" class="dataAnalysis-img">
            </div>
            <img src='https://nonglian.oss-cn-shanghai.aliyuncs.com/center-icon2.png' alt='' class="center-img"> -->
            <!-- <img src="../assets/center-icon1.png" alt="" class="centerBottom-img"> -->
            <div class="center-top">
              <img src="../assets/img/location.png" alt="" class="top-img">
              <div class="location-content">
                <span class="location-title">当前位置：</span>
                <span class="location-text">陕西省</span>
              </div>
            </div>
            <div class="userTotal-box">
              <div class="total-item" v-for="(item, index) in totalData" :key="index">
                <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/home-center-icon1.png" alt="" class="total-icon">
                <div class="total-content">
                  <div class="total-text">{{ item.text }}</div>
                  <div class="totalNum-content">
                    <span class="total-num" v-change="{ eUnit: '' }">{{ item.num }}</span>
                    <span class="total-unit">{{ item.unit }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="map">
              <div class="lable-box">
                <div class="up-line up1"></div>
                <div class="lable locate1">
                  <div class="name">东村数字果园</div>
                  <div class="num">
                    <span v-change="{ eUnit: '' }">{{ num4 }}</span>
                    <span>亩</span>
                  </div>
                  <img src="../assets/img/map-icon11.png" alt="" class="lable-icon">
                </div>
                <div class="lable locate1-2">
                  <div class="name">东村矮化数字果园</div>
                  <div class="num">
                    <span v-change="{ eUnit: '' }">{{ num4s }}</span>
                    <span>亩</span>
                  </div>
                  <img src="../assets/img/map-icon11.png" alt="" class="lable-icon">
                </div>
                <div class="down-line item1"></div>
                <div class="map-round-box1">
                  <div class="round"></div>
                  <div class="dian"></div>
                </div>
                <div class="map-round-box1-2">
                  <div class="round"></div>
                  <div class="dian"></div>
                </div>
                <!-- <div class="line1"></div> -->
              </div>
              <div class="lable-box">
                <div class="up-line up7"></div>
                <div class="lable locate7">
                  <div class="name">道德村数字果园</div>
                  <div class="num">
                    <span v-change="{ eUnit: '' }">{{ num7 }}</span>
                    <span>亩</span>
                  </div>
                  <img src="../assets/img/map-icon11.png" alt="" class="lable-icon">
                </div>
                <div class="down-line item7"></div>
                <div class="map-round-box7">
                  <div class="round"></div>
                  <div class="dian"></div>
                </div>
                <!-- <div class="line1"></div> -->
              </div>
              <div class="lable-box">
                <div class="up-line up8"></div>
                <div class="lable locate8">
                  <div class="name">樱桃塬村数字果园</div>
                  <div class="num">
                    <span v-change="{ eUnit: '' }">{{ num8 }}</span>
                    <span>亩</span>
                  </div>
                  <img src="../assets/img/map-icon11.png" alt="" class="lable-icon">
                </div>
               
                <div class="down-line item8"></div>
                <div class="map-round-box8">
                  <div class="round"></div>
                  <div class="dian"></div>
                </div>
                <!-- <div class="line1"></div> -->
              </div>
              <div class="lable-box">
                <div class="up-line up4"></div>
                <div class="lable locate4">
                  <div class="name">姜家台数字果园</div>
                  <div class="num">
                    <span v-change="{ eUnit: '' }">{{ num3 }}</span>
                    <span>亩</span>
                  </div>
                  <img src="../assets/img/map-icon11.png" alt="" class="lable-icon">
                </div>
                <div class="down-line item4"></div>
                <!-- <div class="line4"></div> -->
                <div class="map-round-box4">
                  <div class="round"></div>
                  <div class="dian"></div>
                </div>
              </div>
              <div class="lable-box">
                <div class="up-line up3"></div>
                <div class="lable locate3">
                  <div class="name">湫塬村数字果园</div>
                  <div class="num">
                    <span v-change="{ eUnit: '' }">{{ num2 }}</span>
                    <span>亩</span>
                  </div>
                  <img src="../assets/img/map-icon11.png" alt="" class="lable-icon">
                </div>
                <div class="down-line item3"></div>
                <div class="map-round-box2">
                  <div class="round"></div>
                  <div class="dian"></div>
                </div>
                <!-- <div class="line3"></div> -->
              </div>
              <div class="lable-box">
                <div class="up-line up5"></div>
                <div class="lable locate5">
                  <div class="name">金村数字果园</div>
                  <div class="num">
                    <span v-change="{ eUnit: '' }">{{ num1 }}</span>
                    <span>亩</span>
                  </div>
                  <img src="../assets/img/map-icon11.png" alt="" class="lable-icon">
                </div>
                <div class="lable locate5-2">
                  <div class="name">金村矮化数字果园</div>
                  <div class="num">
                    <span v-change="{ eUnit: '' }">{{ num1s }}</span>
                    <span>亩</span>
                  </div>
                  <img src="../assets/img/map-icon11.png" alt="" class="lable-icon">
                </div>
                <div class="down-line item5"></div>
                <div class="map-round-box3">
                  <div class="round"></div>
                  <div class="dian"></div>
                </div>
                <div class="map-round-box3-2">
                  <div class="round"></div>
                  <div class="dian"></div>
                </div>
                <!-- <div class="line2"></div> -->
              </div>
              <div class="lable-box">
                <div class="avatar-box">
                  <img src="../assets/img/avatar.png" alt="">
                  <img src="../assets/img/avatar.png" alt="">
                  <img src="../assets/img/avatar.png" alt="">
                </div>
                <div class="up-line up6"></div>
                <div class="lable locate6">
                  <div class="name">照八寺数字果园</div>
                  <div class="num">
                    <span v-change="{ eUnit: '' }">{{ num }}</span>
                    <span>亩</span>
                  </div>
                  <img src="../assets/img/map-icon11.png" alt="" class="lable-icon">
                </div>
                <div class="down-line item6"></div>
                <div class="map-round-box5">
                  <div class="round"></div>
                  <div class="dian"></div>
                </div>
              </div>
            </div>
            <!-- <chinaMap /> -->
            <div class="button-box">
              <router-link to="/page3">
                <button @mouseenter="handleMouseEnter3" @mouseleave="handleMouseLeave3">
                  <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/center-bottom-icon3.png" alt=""
                    class="button-img">
                  <div class="button-text" ref="button3">数字农管</div>
                  <img src="../assets/img/button-icon1.png" alt="" class="button-icon" v-if="showButtonIcon3">
                </button>
              </router-link>
              <router-link to="/page2">
                <button @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
                  <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/center-bottom-icon1.png" alt=""
                    class="button-img">
                  <div class="button-text" ref="button1">数字农服</div>
                  <img src="../assets/img/button-icon1.png" alt="" class="button-icon" v-if="showButtonIcon1">
                </button>
              </router-link>
              <router-link to="/page4">
                <button @mouseenter="handleMouseEnter2" @mouseleave="handleMouseLeave2">
                  <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/center-bottom-icon2.png" alt=""
                    class="button-img">
                  <div class="button-text" ref="button2">数字营销</div>
                  <img src="../assets/img/button-icon1.png" alt="" class="button-icon" v-if="showButtonIcon2">
                </button>
              </router-link>
            </div>
           
          </div>
          <!-- 右边 -->
          <div class="right">
            <centerRight1 />
            <centerRight2 />
            <!-- <centerRight3 /> -->
          </div>
        </div>
        <div class="footer">
          <div class="copyright">Copyright © 2025 农链科技 版权所有</div>
          <div class="footer-record"><a href='https://beian.miit.gov.cn/' target="_blank">陕ICP备19000833号-1</a></div>
            
        </div>
        <div class="bottom">
          <div class="bottom-left">
            <dv-decoration-2 class="decoration-two1" />
            <img src="../assets/img/bottom-icon2.png" alt="" class="bottom-img" />
          </div>
         
          <div class="bottom-right">
            <img src="../assets/img/bottom-icon1.png" alt="" class="bottom-img" />
            <dv-decoration-2 class="decoration-two2" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../utils/drawMixin";
import { formatTime } from '../utils/index.js'
import centerLeft1 from './page1/centerLeft1'
import centerLeft2 from './page1/centerLeft2'
import centerLeft3 from './page1/centerLeft3'
import centerRight1 from './page1/centerRight1'
import centerRight2 from './page1/centerRight2'
// import centerRight3 from './page1/centerRight3'
import axios from 'axios'
// import chinaMap from './page1/chinaMap'

export default {
  mixins: [drawMixin],
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
      decorationColor: ['#568aea', '#000000'],
      showButtonIcon1: false,
      showButtonIcon2: false,
      showButtonIcon3: false,
      totalData: [
        {
          text: '总用户数',
          num: 0,
          unit: '户'
        },
        {
          text: '技术专家',
          num: 23,
          unit: '人'
        },
        {
          text: '采购商',
          num: 1400,
          unit: '人'
        }, {
          text: '农服队',
          num: 12,
          unit: '支'
        },
      ],
      num: 200,
      num1: 60,
      num1s: 100,
      num2: 50,
      num3: 105,
      num4: 167,
      num4s: 100,
      num7: 100,
      num8: 120
    }
  },
  components: {
    centerLeft1,
    centerLeft2,
    centerLeft3,
    centerRight1,
    centerRight2,
    // centerRight3,
    // chinaMap
  },
  mounted() {
    this.timeFn()
    this.cancelLoading()
    this.getPepoleNum()
    this.getPepoleNum2()
    this.getPepoleNum3()
    this.getPepoleNum4()
  },
  beforeDestroy() {
    clearInterval(this.timing)
  },
  methods: {
    handleMouseEnter() {
      this.showButtonIcon1 = true
      this.$refs.button1.style.color = 'rgba(255, 255, 255, 1)'
    },
    handleMouseLeave() {
      this.showButtonIcon1 = false
      this.$refs.button1.style.color = 'rgba(217, 231, 255, 0.5)'
    },
    handleMouseEnter2() {
      this.showButtonIcon2 = true
      this.$refs.button2.style.color = 'rgba(255, 255, 255, 1)'
    },
    handleMouseLeave2() {
      this.showButtonIcon2 = false
      this.$refs.button2.style.color = 'rgba(217, 231, 255, 0.5)'
    },
    handleMouseEnter3() {
      this.showButtonIcon3 = true
      this.$refs.button3.style.color = 'rgba(255, 255, 255, 1)'
    },
    handleMouseLeave3() {
      this.showButtonIcon3 = false
      this.$refs.button3.style.color = 'rgba(217, 231, 255, 0.5)'
    },
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss')
        this.dateYear = formatTime(new Date(), 'yyyy.MM.dd')
        this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    async getPepoleNum() {
      await axios.get('https://cloud.nongliankeji.com/apis/mate-marketing/client/circle/getDetailById?circleId=1707216834386669569').then(res => {
        console.log(res.data.data.userNum);
        localStorage.setItem('UserNum',res.data.data.userNum)
        if(res.data.data.userNum>=1117){
          this.totalData[0].num = res.data.data.userNum;
        }else{
          this.totalData[0].num=1117
        }
        // this.num = Math.trunc(this.totalData[0].num * 0.4) 
        // this.num1 =Math.trunc(this.totalData[0].num * 0.15)
        // this.num2 = Math.trunc(this.totalData[0].num * 0.15)
        // this.num3 = Math.trunc(this.totalData[0].num * 0.15)
        // this.num4= this.totalData[0].num-(this.num+this.num1+this.num2+this.num3)
      })
    },
    async getPepoleNum2() {
      await axios.get('https://cloud.nongliankeji.com/apis/mate-marketing/client/publish/getPublishCount?circleId=1707216834386669569&publishType=labourAndAssignment').then(res => {
        console.log(res.data.data);
        localStorage.setItem('PublishCount',res.data.data)
        this.totalData[3].num = res.data.data
      })
    },
    async getPepoleNum3() {
      await axios.get('https://cloud.nongliankeji.com/apis/mate-marketing/client/user/getUserPageByAuthTypes?authTypes=%E5%86%9C%E6%9C%8D%E9%98%9F%2C%E4%B9%A1%E5%9C%9F%E4%B8%93%E5%AE%B6%2C%E5%86%9C%E6%8A%80%E6%8E%A8%E5%B9%BF%E5%91%98%2C%E5%86%9C%E4%B8%9A%E9%99%A2%E6%A0%A1%E4%B8%93%E5%AE%B6').then(res => {
        console.log(res.data.data.total, '333');
        this.totalData[1].num = res.data.data.total
        // this.totalData[3].num=res.data.data
      })
    },
    async getPepoleNum4() {
      await axios.get('https://cloud.nongliankeji.com/apis/mate-marketing/client/publish/getPublishCount?circleId=1707216834386669569&publishType=supplyAndNeed').then(res => {
        console.log(res.data.data, '222');
        this.totalData[2].num = res.data.data
        // this.totalData[3].num=res.data.data
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/index.scss';
</style>
