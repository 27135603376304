<template>
  <div id="centerRight1">
    <div class="title">
      <span class="text">主栽品类</span>
    </div>
    <div class="content">
      <table class="mb-5" cellspaing="0" align="center" width="100%">
        <thead>
          <tr>
            <th>上市时间</th>
            <th>品类</th>
            <th>种植面积</th>
            <!-- <th>位置</th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in data" :key="index">
            <td>{{ item.time }}</td>
            <td>
              {{ item.text }}
            </td>
            <td>
              {{ item.num }}
            </td>
            <!-- <td>
              {{ item.spend }}
            </td> -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [
        {
          time: '7月下旬',
          text: '鲁丽',
          num: '200亩',
          // spend: '富县北道德'
        },
        {
          time: '8月中下旬',
          text: '嘎啦',
          num: '632亩',
          // spend: '富县北道德'
        },
        {
          time: '9月下旬',
          text: '芳明',
          num: '112亩',
          // spend: '富县北道德'
        },
        {
          time: '9月下旬',
          text: '秦脆',
          num: '100亩',
          // spend: '富县北道德'
        },
        {
          time: '9月下旬',
          text: '玉华早富',
          num: '150亩',
          // spend: '富县北道德'
        },
        {
          time: '9月中下旬',
          text: '弘前富士',
          num: '120亩',
          // spend: '富县北道德'
        },
        {
          time: '10月中下旬',
          text: '红富士',
          num: '31000亩',
          // spend: '富县北道德'
        },
        {
          time: '10月下旬',
          text: '秦冠',
          num: '500亩',
          // spend: '富县北道德'
        },
        {
          time: '10月下旬',
          text: '红星',
          num: '200亩',
          // spend: '富县北道德'
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
// $box-height: 410px;
// $box-width: 300px;
#centerRight1 {
  width: 400px;
  margin-top: 20px;

  .title {
    width: 100%;
    height: 32px;
    background-image: url('../../assets/img/title.png');
    background-size: cover;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .text {
      margin-left: 38px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1.78px;
    }
  }

  .content {
    margin-top: 10px;
  }

  .table-img {
    width: 65px;
    height: 40px;
    border-radius: 8px;
  }

  th {
    padding: 5px 0;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 2px;
    color: rgba(51, 204, 204, 1);
  }

  td {
    text-align: center;
    padding: 7px 13px;
    font-size: 14px;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
    padding: 10px 0;
  }
}
</style>
